import React from "react";

const Footer = () => {
    return(
        <div class="w-full h-420 b-2 flex justify-center">
          <div>
           <div class="w-full flex justify-center mb-1 mt-6">
             <img alt="logo" class="h-16" src={require("../assets/logo.png")}></img>
           </div>
           <div class="border-t-2 b-color add-margin">
              <h1 class="text-white font-sec pl-8 pr-8 remove-padding pt-1 mobile-footer-h">Puiestee 10/1 - Reg.nr. 12010742 - EE101411271 - IBAN: EE192200221050845751 - info@msaelekter.ee</h1>
           </div>     
           </div>     
        </div>
    )
}

export default Footer;