import React from "react";
import gsap from "gsap";
const Navbar = () => {
    window.onload = function () {
        gsap.to(".navbg", {opacity:0, duration:0})
        var previousPosition = window.pageYOffset || document.documentElement.scrollTop;

        window.onscroll = function () {
            var currentPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (previousPosition > currentPosition) {
                if (currentPosition === 0) { // if the user has scrolled back to the top
                    gsap.to(".navbg", {opacity:0})
                    gsap.to(".navbar-logo", {marginTop:96, height:128})
                }
            } else {
                gsap.to(".navbg", {opacity:1})
                gsap.to(".navbar-logo", {marginTop:0, height:48})
            }
            previousPosition = currentPosition;
        };
    }

    function scrollToTop() {
        document.body.scrollIntoView({behavior: "smooth" });
    }


    return (
        <div id="navbar" class="flex w-screen h-12 fixed bg-transparent remove-on-mobile">
            <div class="absolute w-screen h-12 bg-transparent navbg b-2"></div>
            <div class="absolute">
              <img alt="logo" src={require("../assets/logo.png")} class="ml-20 mt-24 h-32 navbar-logo cursor-pointer" onClick={() => scrollToTop()}></img>
            </div>
            <div class="absolute right-16 flex items-center h-12 navbar-container-siim">
                <button onClick={() => document.getElementById("title-ettevõtte").scrollIntoView({ block: 'center', behavior: "smooth" })} class="w-28 h-full nav-btn pl-1 pr-1">
                    <h1 class="font-sec text-white">Ettevõttest</h1>
                </button>
                <button onClick={() => document.getElementById("title-teenused").scrollIntoView({ block: 'center', behavior: "smooth" })} class="w-28 h-full nav-btn pl-1 pr-1">
                    <h1 class="font-sec text-white">Teenused</h1>
                </button>
                <button onClick={() => document.getElementById("title-kontakt").scrollIntoView({ block: 'center', behavior: "smooth" })} class="w-28 h-full nav-btn pl-1 pr-1">
                    <h1 class="font-sec text-white">Kontakt</h1>
                </button>
                <button onClick={() => document.getElementById("title-projektimüük").scrollIntoView({ block: 'center', behavior: "smooth" })} class="w-28 h-full nav-btn pl-1 pr-1">
                    <h1 class="font-sec text-white">Projektimüük</h1>
                </button>
                <img onClick={()=> window.location.href = "https://www.instagram.com/msa_elekter"} class="w-8 h-8 rounded-full p-1 mr-2 ml-2 nav-btn-social cursor-pointer" alt="" src={require("../assets/icons8-instagram-96.png")}></img>
                <img onClick={()=> window.location.href = "https://www.facebook.com/profile.php?id=100057395652440"} class="w-8 h-8 rounded-full p-1 nav-btn-social cursor-pointer" alt="" src={require("../assets/icons8-facebook-60.png")}></img>
            </div>
        </div>
    )
}

export default Navbar;