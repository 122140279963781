import React from "react";

const Separator = ({ side }) => {
    if (side === "left") {
        return (
            <svg width="803" height="13" viewBox="0 0 803 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect id="SEP-RECT" width="803" height="13" fill="url(#paint0_linear_423_4)" />
                <defs>
                    <linearGradient id="paint0_linear_423_4" x1="5.5" y1="6.99986" x2="803" y2="6.99979" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00A6E1" />
                        <stop offset="0.994278" stop-color="#00A6E1" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        )
    } else if (side === "right") {
        return (
            <div class="sep-adjuster">
            <svg width="803" height="13" viewBox="0 0 803 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="803" height="13" fill="url(#paint0_linear_402_4)" />
                <defs>
                    <linearGradient id="paint0_linear_402_4" x1="797" y1="13" x2="3.34004e-06" y2="13.0002" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00A6E1" />
                        <stop offset="0.977646" stop-color="#00A6E1" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            </div>

        )
    } else {
        throw Error("Side is missing or typed wrong")
    }
}

export default Separator;